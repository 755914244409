import styled, { css } from 'styled-components';

import { Box } from 'components/Box';
import { colors, cssBreakpoints } from 'theme/theme';

const containerWithDividerCss = css`
  border-bottom: solid 1px ${colors.elementGrey};

  &:last-child {
    border-bottom: 0;
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    border-bottom: none;
    border-right: solid 1px ${colors.elementGrey};

    &:last-child {
      border-right: 0;
    }
  }
`;

export const CmsThreeStepCtaBlockStepContainerBox = styled(Box)<{
  $large: boolean;
  $withLinks: boolean;
}>`
  ${(props) =>
    props.$large || props.$withLinks ? '' : containerWithDividerCss}
`;
