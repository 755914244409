import { ApiFilestackImageWithTextData } from 'api/types/ApiFilestackImageData';
import { Box } from 'components/Box';
import { FilestackImage } from 'components/FilestackImage/FilestackImage';
import { StyledNativeLinkWithRef } from 'components/Link/StyledNativeLinkWithRef';
import { BodySmall } from 'components/Text/BodySmall';
import { H3 } from 'components/Text/H3';
import { gutterWidth } from 'theme/theme';
import { trackAnchorClicked } from 'utils/analytics/track/trackAnchorClicked';
import { trackClicked } from 'utils/analytics/track/trackClicked';

import { CmsThreeStepCtaBlockStepBox } from './CmsThreeStepCtaBlockStepBox';
import { CmsThreeStepCtaBlockStepContainerBox } from './CmsTreeStepCtaBlockStep.styled';

type Props = {
  blockIndex: number;
  headline: string;
  image: ApiFilestackImageWithTextData | null;
  text: string;
  url: string | null;
  withImages: boolean;
  withLinks: boolean;
  large: boolean;
  pageUpdated: string;
  step: string;
  'data-qa-id': string;
};

export function CmsTreeStepCtaBlockStep({
  blockIndex,
  headline,
  image,
  text,
  url,
  withImages,
  withLinks,
  large,
  pageUpdated,
  step,
  'data-qa-id': qaId,
}: Props) {
  return (
    <CmsThreeStepCtaBlockStepContainerBox
      onClick={() => {
        if (!url || !window) return;

        trackClicked('CMS Block', {
          block_position: blockIndex,
          block_type: 'Three Step Call to Action',
          cta_type: 'Tile',
          page_updated: pageUpdated,
          step,
        });

        window.open(url, '_self');
      }}
      data-qa-id={qaId}
      maxWidth="400px"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      flexDirection="column"
      flex="0 0 auto"
      width={[1, null, null, 1 / 3]}
      px={[0, null, null, gutterWidth / 2]}
      py={[gutterWidth / 2, null, null, 0]}
      $large={large}
      $withLinks={withLinks}
    >
      <CmsThreeStepCtaBlockStepBox
        $url={url}
        withLinks={withLinks}
        large={large}
      >
        {(image || withImages) && (
          <Box
            position="relative"
            overflow="hidden"
            mb={24}
            width="100%"
            height={large ? 0 : 80}
            pb={large ? `${200 / 3}%` : 0}
          >
            {image && (
              <Box
                width="100%"
                height="100%"
                position="absolute"
                left="50%"
                transform="translateX(-50%)"
                textAlign="center"
              >
                <FilestackImage
                  data-qa-id={`cta-step-image${large ? '-large' : ''}`}
                  alt={image.altText || ''}
                  data={image}
                  height={large ? 240 : 80}
                  width={352}
                  noCrop
                  expandSize
                  objectFit={large ? 'cover' : 'contain'}
                />
              </Box>
            )}
          </Box>
        )}
        <Box width="100%" textAlign={large ? 'left' : 'center'}>
          <H3 mb="8px" styleAs={large ? 'h4' : 'h3'}>
            {url ? (
              <StyledNativeLinkWithRef
                href={url}
                onClick={(event) =>
                  trackAnchorClicked(event, 'CMS Block', {
                    block_position: blockIndex,
                    block_type: 'Three Step Call to Action',
                    cta_type: 'Tile',
                    page_updated: pageUpdated,
                    step,
                  })
                }
              >
                {headline}
              </StyledNativeLinkWithRef>
            ) : (
              headline
            )}
          </H3>
          <BodySmall
            analyticsData={{
              eventName: 'CMS Block',
              eventProperties: {
                block_position: blockIndex,
                block_type: 'Three Step CTA',
              },
            }}
            px={[0, null, large ? null : gutterWidth / 4]}
            mb={-16}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </Box>
      </CmsThreeStepCtaBlockStepBox>
    </CmsThreeStepCtaBlockStepContainerBox>
  );
}
