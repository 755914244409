import { Box } from 'components/Box';
import { LegacyPrimaryButton } from 'components/LegacyButton/LegacyPrimaryButton';
import { StyledNativeLinkWithRef } from 'components/Link/StyledNativeLinkWithRef';
import { Body } from 'components/Text/Body';
import { RichTextHeadline } from 'components/Text/RichTextHeadline';
import {
  CmsApiThreeStepCtaBlockData,
  cmsThreeStepCtaBlockSteps,
} from 'modules/cms/api/types/blocks/CmsApiThreeStepCtaBlock';
import { gutterWidth } from 'theme/theme';
import { trackAnchorClicked } from 'utils/analytics/track/trackAnchorClicked';

import { CmsTreeStepCtaBlockStep } from './CmsTreeStepCtaBlockStep';

type Props = {
  data: CmsApiThreeStepCtaBlockData;
  blockIndex: number;
  pageUpdated: string;
};

export function CmsThreeStepCtaBlock({ data, blockIndex, pageUpdated }: Props) {
  const { mainHeadline, mainText, buttonUrl, buttonText, imageSize, overlap } =
    data;

  const withImages = cmsThreeStepCtaBlockSteps.some(
    (step) => data[`step${step}Image`],
  );

  const withLinks = cmsThreeStepCtaBlockSteps.some(
    (step) => data[`step${step}Url`],
  );

  return (
    <Box
      position="relative"
      data-qa-id={`three-step-cta-${blockIndex}`}
      display="flex"
      alignItems="center"
      width="100%"
      flexDirection="column"
      justifyContent="center"
      mt={overlap ? [null, -90, -120, -110] : undefined}
    >
      {(mainHeadline || mainText) && (
        <Box width="100%" textAlign="center" mb={24}>
          {mainHeadline && (
            <RichTextHeadline $noMarginTop>{mainHeadline}</RichTextHeadline>
          )}

          {mainText && (
            <Body
              analyticsData={{
                eventName: 'CMS Block',
                eventProperties: {
                  block_position: blockIndex,
                  block_type: 'Three Step CTA',
                },
              }}
              dangerouslySetInnerHTML={{
                __html: mainText,
              }}
            />
          )}
        </Box>
      )}

      <Box
        display="flex"
        alignItems={['center', null, null, 'stretch']}
        flexDirection={['column', 'column', 'column', 'row']}
        justifyContent="center"
        mx={[0, null, null, -gutterWidth / 2]}
        width={[1, null, null, `calc(100% + ${gutterWidth}px)`]}
      >
        {cmsThreeStepCtaBlockSteps.map((step, index) => (
          <CmsTreeStepCtaBlockStep
            key={step}
            blockIndex={blockIndex}
            data-qa-id={`step-${index}`}
            headline={data[`step${step}Headline`]}
            text={data[`step${step}Text`]}
            image={data[`step${step}Image`]}
            url={data[`step${step}Url`]}
            withImages={withImages}
            withLinks={withLinks}
            large={imageSize === 'LARGE'}
            pageUpdated={pageUpdated}
            step={step}
          />
        ))}
      </Box>

      {buttonText && buttonUrl && (
        <LegacyPrimaryButton
          component={StyledNativeLinkWithRef}
          href={buttonUrl}
          mt={24}
          onClick={(event) =>
            trackAnchorClicked(event, 'CMS Block', {
              block_position: blockIndex,
              block_type: 'Three Step Call to Action',
              cta_type: 'Button',
              page_updated: pageUpdated,
            })
          }
        >
          {buttonText}
        </LegacyPrimaryButton>
      )}
    </Box>
  );
}
